import { useState } from 'react';
import BoxSelector from '../components/BoxSelector/BoxSelector';
import GridProdutos from '../components/GridProduto/GridProdutos';
import Header from '../components/Header/Header';
import { QrCode } from '../components/QrCode/QrCode';
import HomeStyled from './HomeStyled';


const options = ["Conteudos Sincronizados", "Produtos", "QR Code"]



type HomeTypes = {
}

const Home = ({ }: HomeTypes) => {
  const [section, setSection] = useState("");
  const selected = (str: string) => {
    setSection(str);
  };

  const optionsRender: { [key: string]: boolean } = {
    "Conteudos Sincronizados": section === "Conteudos Sincronizados",
    "Produtos": section === "Produtos",
    "QR Code": section === "QR Code"
  };

  return (

    <div className='containerMain'>
      <Header />
      <HomeStyled >
        <div className='c'>
          <div className='t'>
            PORTAL FRANQUEADO
          </div>
          <BoxSelector options={options} selected={selected} flexDirection='column' width='238px' justifyContent="flex-start" marginLeft="25px" />
          <QrCode show={optionsRender["QR Code"]} />
          
          <GridProdutos show={optionsRender["Produtos"]}  />
        </div>
        </HomeStyled>

    </div>


  )
}

export default Home;
import React from "react";
import WindowComponentStyled from "./WindowComponentStyled";

type Props = {
    title: string;
    children?: React.ReactNode;
    width?: number| string;
    show?: boolean
}

const WindowComponent = ({ title, children, width, show }: Props) => {
    return (
        <WindowComponentStyled width={width} show={show}>
            <div className="c">
                <div className="t">{title}</div>
             
                <div className="c_1" style={{width: "auto"}}>    {children}</div>
            </div>
        </WindowComponentStyled >
    )
}

export default WindowComponent;
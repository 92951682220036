import styled from 'styled-components';

interface Props {
    width?: number | string;
    show?: boolean;
}

const WindowComponentStyled = styled.div<Props>`
width:  ${({ width = "100%" }) => width };
height: auto;
display: ${({show = true}) => show ? 'block' : 'none'} ;
>.c{

    margin: 25px 0px 0 0;
    border-radius: 3px 3px 0px 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  >.c_1{
    padding: 25px;
  }
    > .t {
    height: 44px;
    background: rgb(33, 33, 33);
    border-radius: 3px 3px 0px 0px;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 0 25px;
    text-transform: uppercase;
    font-family: "Lato Bold";
    }
}
@media only screen and (max-width: 480px) {
  >.c{
    margin: 15px 0px 0 0;
       >.c_1{
        padding: 15px;
        }
       > .t {
       padding: 0 15px;
         }
      }   
    }

`

export default WindowComponentStyled;
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { checkAuthenticatedSession, isAuthenticated } from './components/Authentication';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Login from './pages/Login';

const App: React.FC = () => {
  console.log(isAuthenticated());
  console.log(checkAuthenticatedSession());
  return (
    <Router >
      <Routes>
        {/* <Route path="/Home" element={
          <AuthenticatedRoute
            component={() => <Home  />}
          />} /> */}
        <Route path="/Home" element={<Home />} />;
        <Route path="/" element={<Login />} />;
      </Routes>
      <Footer />
    </Router>

  );
};

export default App;
import styled from "styled-components";

const GridDiffViewerStyled = styled.div`
   
 >div {
    display: flex;
    gap: 15px;
    flex-direction: column;
    >li {
    font-family: 'Lato Bold';
    color: #6D7174;
    
 }
 }

 `

export default GridDiffViewerStyled
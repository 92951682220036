import WindowComponent from "../WindowComponent/WindowComponent"
import { QrCodeStyle } from "./QrCodeStyle"

type Props = {
    show?: boolean
}

export const QrCode = ({show}: Props) => {
  return (
    <WindowComponent title="QR Code" show={show}>
        <QrCodeStyle >

            
        </QrCodeStyle>
    </WindowComponent>
  )
}
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
    loginRedirectPath: string
    children?: ReactNode
}

const isAuthenticated = () => {
    return checkAuthenticatedSession().success ?? false;
};

const handleLogin = (Data: Object) => {
    localStorage.setItem('Authenticated', JSON.stringify(Data));
};

const handleLogout = () => {
    localStorage.removeItem('Authenticated');
    console.log("logout")
};

const checkAuthenticatedSession = () => {
    return JSON.parse(localStorage.getItem('Authenticated') ?? "{}")
}


interface AuthenticationRouteProps {

    component: React.ComponentType<any>;
}

const AuthenticatedRoute: React.FC<AuthenticationRouteProps> = ({ component: Component }) => {

    return (
        isAuthenticated() ? (
            <Component />
        ) : (
            <Navigate to="/" replace={true}  />
        )
    );
};

export { isAuthenticated, handleLogin, handleLogout, checkAuthenticatedSession, AuthenticatedRoute };


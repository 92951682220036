import styled from "styled-components";

const GridProdutosStyled = styled.div`

     @media only screen and (max-width: 1050px)  {
     .container {
    flex-wrap: wrap;
    >.c{
        width: 25%;
    }
    }
    }

`

export default GridProdutosStyled;
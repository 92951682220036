import axios from "axios";
import { FormEvent, useState } from "react";
import API_URL_VT_SIGN from "../../config/apiConfig";
import iconSair from "../../images/icons-sair.png";
import iconSenha from "../../images/icons-senha.png";
import logo from "../../logo.png";
import { ResponseSuccess } from "../../types/types";
import { checkAuthenticatedSession } from "../Authentication";
import Modal from "../Modal/Modal";
import HeaderStyled from "./HeaderStyle";


type Props = {}


interface ResponseData extends ResponseSuccess {
    data: Record<string, any>;
}


const Header = (props: Props) => {
    var session = checkAuthenticatedSession();
    const [ShowModalAltPass, setShowModalAltPass] = useState(false);
    const [Password, setPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [CfPassword, setCfPassword] = useState("");

    const handleSubmitAltPass = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        try {
           /*  {
                action: "alterar_senha",
                SenhaAtual: Password,
                NovaSenha: NewPassword,
                ConfirmSenha: CfPassword
            } */
            //
            const response: ResponseData = await axios.post(`https://painel.vtsign.com.br/Login.vtt?action=alterar_senha&SenhaAtual=${Password}&NovaSenha=${NewPassword}&ConfirmSenha=${CfPassword}` );
            let Data;

            if (typeof response.data === 'string') {
                Data = eval("(" + response.data + ")");
            } else {
                Data = response.data;
            } 
             if (Data.success) {
                  console.log(Data.message)
               
              } else {
                  console.log(Data.message)
              } 

        } catch (error: unknown) {
            if (error instanceof Error) {
                console.log(error.message);
            }
        }
    }


    return (
        <HeaderStyled>
            <header>
                <div className="c1">
                    <div>
                        <img src={logo} className="logo" alt="" />
                    </div>

                    <div className="c1">
                        <div className="c1 c">
                            <p>
                                {session?.data?.NmUsuario} -
                            </p>
                        </div>
                        <div className="c2 c">
                            <div className="c1 c">
                                <img src={iconSenha} alt="" onClick={() => { setShowModalAltPass(true) }} />
                            </div>
                            <div className="c2 c" >
                                <img src={iconSair} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="c2">
                    <ul>
                        <li>Portal Franqueado</li>
                    </ul>
                </div>
            </header>
            <Modal handleSubmit={handleSubmitAltPass} buttons={[{
                text: "Salvar", 
            }]} onClose={() => { setShowModalAltPass(false) }} type="SubmitForm" show={ShowModalAltPass} title="Alterar Senha" inputs={[
                { Label: "Senha Atual", onChange: (event) => { setPassword(event.target.value) }, type: "password" },
                { Label: "Nova Senha", onChange: (event) => { setNewPassword(event.target.value) }, type: "password" },
                { Label: "Confirmar Senha", onChange: (event) => { setCfPassword(event.target.value) }, type: "password" }
            ]} />

            <Modal  type="MessageBox" message="teste"  />
        </HeaderStyled>

    )
}

export default Header;



import styled from "styled-components";

const HomeStyled = styled.div`

 >.c {
    margin: 0 25px 50px 25px;
    >.t{
     height: 85px;
     font-family: 'Lato Black';
     display: flex;
     align-items: center;
     color: #212121;
     font-size: 27px;
    }
}
    @media only screen and (max-width: 600px) {
        >.c {


         >.t {
            justify-content: center;
            font-size: 22px;
            
        }
        .c_box_selector {
                max-width: 100%;
            }
    }

   
    @media only screen and (max-width: 480px) {
        >.c {
          margin: 0 15px 50px 15px;
        }   
       
    }
 }

 `

export default HomeStyled 